// components
import AboutPageMain from '../components/about/AboutPageMain';
import AboutPageGeneral from '../components/about/AboutPageGeneral';
import AboutPageMission from '../components/about/AboutPageMission';
import AboutPageValues from '../components/about/AboutPageValues';

export default function About() {
     return(
          <div>
               <AboutPageMain />
               <AboutPageGeneral />
               <AboutPageMission />
               <AboutPageValues />
          </div>
     );
}