// hooks 
import { useState, useEffect } from 'react';
// assets 
import img from '../../assets/CONTACTO.webp';

export default function ContactPageForm() {

     const [name, setName] = useState('');
     const [email, setEmail] = useState('');
     const [title, setTitle] = useState('');
     const [phone, setPhone] = useState('');
     const [message, setMessage] = useState('');

     const [phoneValidation, setPhoneValidation] = useState(false);

     // validate phone number 
     const validatePhoneNumber = (num) => {
          const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
          return re.test(num);
     }

     useEffect(() => {
          setPhoneValidation(validatePhoneNumber(phone));
     }, [phone]);

     // submit form
     const handleSubmit = (e) => {
          e.preventDefault();
          if (phoneValidation) {
               fetch("https://formsubmit.co/ajax/foverbagh@foverbagh.com", {
                    method: 'POST',
                    headers: {
                         'Content-Type': 'application/json',
                         'Accept': 'application/json',
                    },
                    body: JSON.stringify({
                         name,
                         email,
                         phone,
                         title,
                         message,
                    })
               })
               .then(response => response.json())
               .then(() => console.log(validatePhoneNumber(phone)))
               .then(() => alert('¡Envío exitoso!'))
               .catch(error => console.log(error));
          } else {
               alert('El número telefónico no es válido.')
          }
     }

     return(
          <div className='w-full flex 2xl:flex-row flex-col-reverse gap-y-32 justify-center items-center gap-x-20 px-10 xl:p-32 2xl:px-64 py-36'>
          {/* image on left */}
          <div className='w-[80%] 2xl:w-[40%]'>
               <img src={img} alt="" className='object-cover w-full h-full' />
          </div>
          {/* form */}
          <form className='w-[90%] 2xl:w-[60%]' onSubmit={handleSubmit} autoComplete="off">
                    <div className='w-full'>
                         <div className='flex flex-col md:flex-row justify-between gap-x-5'>
                              {/* name */}
                              <input required maxLength="50" type="text" placeholder='Nombre Completo*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='name' onChange={(e) => setName(e.target.value)} />
                              {/* email */}
                              <input required maxLength="50" type="email" placeholder='Email*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='email' onChange={(e) => setEmail(e.target.value)} />
                         </div>
                         <div className='flex flex-col md:flex-row justify-between gap-x-5'>
                              {/* title */}
                              <input required maxLength="100" type="text" placeholder='Motivo*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='title' onChange={(e) => setTitle(e.target.value)} />
                              {/* phone number */}
                              <input required type="text" placeholder='Número de teléfono' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' maxLength="20" name='phone' onChange={(e) => setPhone(e.target.value)} />
                         </div>
                    </div>
                    {/* message */}
                    <textarea className='w-full border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' placeholder='Mensaje' cols="30" rows="10" name='message' maxLength="1000" required onChange={(e) => setMessage(e.target.value)}></textarea>
                    {/* button */}
                    <button className='mt-10 w-full bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg' type='submit'>Enviar</button>
               </form>
          </div>
     );
}