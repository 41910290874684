// icons 
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function PrivacyPolicy() {
     return(
          <div className='w-full'>
               {/* title */}
               <div className='w-full flex flex-col items-center gap-y-5 bg-red-900 pt-36 pb-16'>
                    <h1 className='text-4xl md:text-7xl font-semibold text-center w-full text-white'>Aviso de Privacidad</h1>
                    <div className='w-8 text-white'>
                         <ChevronDownIcon />
                    </div>
               </div>
               {/* content */}
               <div className='flex flex-col gap-y-20 mt-16 px-16 lg:px-64 pb-16'>
                    <div className='flex justify-end'>
                         <span className='text-xl font-semibold'>Última actualización: 19 de febrero del 2023</span>
                    </div>
                    <div>
                         <p className='text-gray-500 text-lg mb-5'>
                         Cada vez que usted en calidad de usuario accede a nuestro Sitio Web y se encuentra con los servicios que se ofrecen en Foverbagh, se recopilan y procesan 
                         información sobre el usuario, a la que se le hace referencia como sus “datos personales”. Este aviso de privacidad describe el proceso de recopilación de datos personales 
                         y cómo Foverbagh, hace uso, trata y protege estos datos personales recabados. También se enuncian los derechos del usuario con respecto a sus datos personales 
                         y cómo puede ponerse en contacto con Foverbagh para poder actualizar, rectificar o eliminar estos datos.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Debido a la naturaleza de gran importancia que contiene este Aviso de Privacidad, Foverbagh invita de manera muy atenta a todos sus usuarios a leer y 
                         familiarizarse con este documento. Si algún tipo de información no queda clara, por favor póngase en contacto con nosotros vía correo electrónico en foverbagh@foverbagh.com.mx.
                         </p>
                         <p className='text-gray-500 text-lg'>
                         Al hacer uso de nuestro Sitio Web (foverbagh.com.mx) usted está de acuerdo con todos los apartados de este Aviso de Privacidad que se enuncian a continuación:
                         </p>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>A) De la recopilación de datos personales.</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         Para que los servicios de Foverbagh se encuentren disponibles en su totalidad para usted o para cumplir con determinadas actividades comerciales u obligaciones 
                         legales, es necesario por nuestra parte recopilar y usar datos personales del usuario. En caso de que no proporcione la información que solicitamos, es posible que no podamos 
                         ofrecerle los servicios que ha solicitado. Parte de esta información la recopilamos directamente en persona o a través de un sitio web, aplicación o redes sociales.    
                         </p>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>B) De las ocasiones de recopilación de datos personales.</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         Usted como usuario de nuestro Sitio Web está en contacto con nuestros servicios contables y de asesoría fiscal, los cuales se incluyen en diferentes medios de difusión e 
                         incluso aplicaciones móviles y motores de búsqueda.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Al hacer uso de la navegación de internet e interactuar con nuestro Sitio Web o redes sociales, Foverbagh recopila datos personales cuando:
                         </p>
                         <ul className='text-gray-500 text-lg mb-5'>
                         <li>(i) Llena un formulario de contacto</li>
                         <li>(ii) Participa en una promoción, activación, concurso o evento.</li>
                         <li>(iii) Solicita una cotización por correo electrónico.</li>
                         <li>(iv) Envía uno de sus comentarios o participa en una encuesta.</li>
                         <li>(v) Comunica con nosotros a través de redes sociales. </li>
                         <li>(vi) Se pone en contacto con nosotros en busca de asistencia.</li>
                         <li>(vii) Presenta una solicitud de empleo o alianza comercial.</li>
                         </ul>
                         <p className='text-gray-500 text-lg mb-5'>
                         Los datos que pueden ser pedidos en cualquiera de esas situaciones son: 
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Nombre, fecha de nacimiento, correo electrónico, número de teléfono, dirección, información de pago, detalles de cuentas bancarias, prueba fotográfica de mayoría de edad, 
                         comentarios sobre nuestros servicios, información sobre sus preferencias y hábitos de consumo, comunicación de marketing y preferencias de cookies, historial de empleo, 
                         expectativas salariales, así como detalles de cuando se pone en contacto con nosotros, incluyendo grabaciones de cualquier llamada telefónica o registros, así como copias 
                         de correos electrónicos, fotografías y videos enviados por el usuario o tomados en alguno de nuestros eventos, e información de perfil de redes sociales. 
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         En caso de que usted revele cualquier tipo de información personal sobre otras personas a nuestra compañía o a otros proveedores de servicios en relación con nuestros 
                         productos y servicios, usted asegura que tiene la autoridad para ello y para permitirnos usar dicha información de acuerdo con este Aviso de Privacidad.
                         </p>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>C) De la recopilación de datos personales a través de fuentes externas.</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         Foverbagh en su función como empresa sujeta a las leyes mexicanas recopila información personal de otras fuentes de terceros, como sería la revisión de los 
                         datos personales recibidos como parte de las comprobaciones rutinarias de verificación de formas de pago para evitar fraudes. 
                         </p>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>D) Del uso de los datos personales.</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         Foverbagh hará uso de sus datos personales para fines comerciales legítimos, como se describe a continuación.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Brindar acceso a nuestros servicios:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Podemos usar su información personal para permitirle disponer de nuestros servicios, eventos y aplicaciones móviles. Por ejemplo, dándole acceso a una cuenta registrada. 
                         También podemos usar su información personal para enviarle datos administrativos, como cambios en nuestros términos, condiciones y políticas. Realizaremos dichas actividades 
                         para gestionar nuestra relación contractual con usted o para cumplir con una obligación legal.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Administrar promociones de premios, ofertas y eventos:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Podemos realizar promociones de premios, concursos y otras ofertas promocionales. Si participa, usaremos su información para administrar tales promociones y ofertas. 
                         Si tiene la suerte de ganar, podemos solicitarle más información para que podamos darle su premio y le pediremos que nos proporcione una copia de su identificación para 
                         que podamos verificar que usted es legalmente mayor de edad y, de ser necesario, cumplir con los requisitos de elegibilidad. Algunas de estas promociones incluyen normas 
                         adicionales con información acerca de cómo usaremos y difundiremos su información personal, usamos esta información para gestionar nuestra relación contractual con usted.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Servicio de atención al cliente:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Si se pone en contacto con nosotros, o viceversa, utilizaremos su información personal, como su información de contacto, con el fin de brindarle asistencia, atender consultas 
                         y reclamaciones. Realizaremos dichas actividades para gestionar nuestra relación contractual con usted, para nuestros fines comerciales legítimos o para cumplir con una 
                         obligación legal.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Gestión de servicios:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Usamos información sobre usted para gestionar tus servicios correctamente (incluyendo para determinar si cumple con la edad legal de mayoría de edad en su país), También 
                         podemos utilizar sus datos para protegerlo identificar posibles transacciones fraudulentas u operaciones de carácter ilícito. Realizaremos dichas actividades para gestionar 
                         nuestra relación contractual con usted o para cumplir con una obligación legal. 
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Envío de información sobre nuestros productos y servicios (y productos y servicios de terceros)</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Si nos ha pedido recibir comunicaciones de marketing de nuestra parte, es posible que le enviemos información sobre nuestros productos, boletines informativos, promociones, 
                         ofertas u otra información que consideremos pueda ser de su interés. Usamos la información personal para enviarle comunicaciones de marketing. Realizaremos estas comunicaciones 
                         ya sea porque tenemos un interés legítimo o porque usted nos lo ha permitido.
                         </p>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>E) De nuestros fines comerciales.</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         Usamos su información personal para cumplir con nuestros fines comerciales, entre los que se incluyen:
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Realizar análisis de la información para, por ejemplo: mejorar la eficacia de nuestros servicios, realizar revisiones para verificar que nuestros procesos internos funcionen 
                         correctamente y cumplan con los requerimientos legales, reguladores y contractuales.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Realizar controles relacionados con la seguridad y el fraude para, por ejemplo, detectar e impedir ciberataques o intentos de robo de identidad; desarrollar nuevos productos 
                         y servicios; considerar formas de potenciar, mejorar o modificar nuestros productos y servicios actuales; identificar tendencias de uso para, por ejemplo, comprender qué partes 
                         de nuestros servicios interesan en mayor medida a los usuarios; determinar la eficacia de nuestras campañas publicitarias, de modo que podamos adaptarlas a las necesidades e 
                         intereses de nuestros usuarios; y para llevar a cabo y expandir nuestras actividades comerciales como, por ejemplo, comprender qué partes de nuestros servicios interesan en 
                         mayor medida a nuestros usuarios, de modo que podamos centrarnos en satisfacer sus peticiones.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Realizamos dichas actividades para gestionar nuestra relación contractual con usted, para cumplir con una obligación legal o con un fin comercial legítimo, estás actividades 
                         incluyen: 
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>1.- Personalización de nuestros servicios</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Podemos analizar la información personal recopilada para realizar informes comerciales y ofrecer servicios personalizados, entre los que se incluyen: (i) analizar o predecir 
                         las preferencias de nuestros usuarios para redactar informes de tendencias conjuntas sobre cómo se usa nuestro contenido digital, pudiendo así mejorar nuestros servicios; 
                         (ii) comprenderla mejor de modo que podamos personalizar nuestras interacciones con usted y ofrecerle información u ofertas acordes con sus intereses; y (iii) comprender 
                         mejor sus preferencias de modo que podamos ofrecerle, mediante nuestros servicios, un contenido que creamos que sea relevante e interesante para usted.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Cuando use una de nuestras tiendas en línea para adquirir nuestros cursos o productos digitales, recopilaremos información de usted durante el proceso de pago.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                         Si no completa su compra pero nos pide que guardemos su carrito, le enviaremos un correo electrónico de seguimiento con un recordatorio de su contenido. También podemos 
                         utilizar su información para proporcionar contenido personalizado y recomendaciones cuando le enviemos información sobre sus compras. Le ofreceremos servicios personalizados 
                         ya sea porque nos lo haya permitido o porque tenemos un fin comercial legítimo. 
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>2.- Respuesta a comentarios</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Cuando haya proporcionado un comentario de uno de nuestros servicios o  productos, los publicaremos en la tienda correspondiente, esta información se hará pública, así que no 
                         incluya ninguna información que deba mantenerse confidencial.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>3.- Seguridad corporativa</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Podemos utilizar la información recopilada al supervisar nuestros sitios web, servicios en línea y correos electrónicos para protegerlo a usted, a nuestros empleados y a 
                         nuestros socios. Esta información puede ser compartida con la policía u otras autoridades apropiadas para revisar que no estén cayendo en algún acto ilegítimo o intentos 
                         de fraude. Realizamos dichas actividades para protección de nuestros miembros del equipo así como la de nuestros clientes.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>4.- Información personal compartida</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Compartiremos su información personal con su consentimiento o según sea necesario para completar cualquier transacción legal en orden de poder proporcionar algún servicio 
                         que haya solicitado de manera correceta. Por ahora, no compartimos la información personal con terceros no afiliados. Las siguientes son categorías de destinatarios con 
                         quienes podríamos compartir sus datos.
                         </p>
                         <div className='ml-10'>
                         <h3 className='text-xl font-semibold mb-5'>Proveedores de servicios de terceros: </h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              Usamos proveedores de servicios externos para procesar información personal en nuestro nombre. Estos pueden incluir: ante la Secretaría de Hacienda y Crédito Público 
                              (SHCP) la provisión de atención al cliente, proveedores de alojamiento web, administración de datos de clientes y empresas de mejora de listas, agencias que gestionan 
                              nuestras promociones de premios, empresas que llevan a cabo los pedidos de productos o administran correos, empresas de entrega, procesadores de pagos, agencias de 
                              análisis de datos y proveedores de servicios de correo electrónico. Tratamos de usar aquellos proveedores de servicios que ofrecen una privacidad para la información y 
                              unas medidas de seguridad adecuadas para la protección de los mismos.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Cuándo comparte información personal:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              En foros, chats, perfiles, blogs y otros servicios en los que puede publicar información y contenido (entre los que se incluyen, sin limitación, nuestras páginas de redes 
                              sociales), sea consciente de que cualquier información que publique o divulgue a través de estos servicios será pública y podrá estar disponible para otros usuarios y el 
                              público en general. Mediante su actividad social. Cuando se conecte a los servicios con su cuenta de redes sociales, compartirá información con sus amigos asociados a dicha 
                              cuenta de redes sociales y con el proveedor de la cuenta. De este modo, nos autoriza a facilitar esta información compartida y usted está consciente de que el uso de dicha 
                              información estará regido por la política de privacidad del proveedor de la cuenta de redes sociales.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Transacciones comerciales, fines legales y cumplimiento de la ley:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              Tenemos un interés legítimo para revelar o transferir su información personal a un tercero en caso de que realicemos cualquier reorganización, fusión, venta, empresa 
                              conjunta, traspaso u otra disposición que afecte a toda nuestra empresa o a una parte de la misma, a nuestros activos o a nuestras acciones (entre lo que se incluye 
                              cualquier acción relacionada con la quiebra o procedimientos similares). Entre dichos terceros se pueden incluir, por ejemplo, una entidad compradora y sus asesores.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                              También podemos divulgar información personal para cumplir con obligaciones legales (como el pago de impuestos); para responder a las solicitudes del gobierno u otras 
                              autoridades públicas; para proteger los derechos, privacidad, seguridad o propiedad de Foverbagh, usted u otros; y para permitirnos buscar soluciones 
                              disponibles o limitar los daños que podamos sufrir. Entre estas obligaciones legales y requerimientos gubernamentales se pueden incluir leyes y autoridades externas a su 
                              país de residencia.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Usos y divulgación de otra información:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              Podemos usar y divulgar otra información con cualquier fin, a excepción de cuando las leyes pertinentes lo prohíban. En determinados casos, podemos combinar la información 
                              personal con otra información. En dichos casos, trataremos la información combinada como información personal mientras siga combinada.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Derechos sobre su información personal:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              Puede solicitar acceder a su información personal, modificarla o eliminarla. También puede solicitar recibir una copia electrónica de su información personal para 
                              transferirla a otra compañía (siempre y cuando las leyes pertinentes permitan esta portabilidad de información). En cada caso, es posible que le pidamos que demuestre 
                              su identidad o proporcione información adicional para identificar su información. Atenderemos sus solicitudes cumpliendo con las leyes pertinentes.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                              En Foverbagh nos tomamos la seguridad de su información muy en serio. Esta sección describe nuestras prácticas tecnológicas para mantener su información 
                              protegida. Por favor, haga clic en los enlaces para obtener más información.
                         </p>
                         <p className='text-gray-500 text-lg mb-5'>
                              Contamos con varias medidas y herramientas de seguridad, como firewalls, para ayudar a proteger contra la pérdida, el uso indebido y la alteración de la información personal 
                              bajo nuestro control. Desafortunadamente, no se puede garantizar que la transmisión de datos a través de Internet o del sistema de almacenamiento de datos sea 100% segura. 
                              Por favor, no nos envíe información personal confidencial.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>De la conservación de sus datos personales:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                              Conservamos su información personal durante el tiempo que sea necesario o que esté permitido teniendo en cuenta el (los) fin (es) para el (los) que se recopiló y siempre cumpliendo con las leyes pertinentes. Entre los criterios utilizados para determinar nuestros periodos de conservación de información de tarjetas de pago e información de verificación de edad se incluyen:
                         </p>
                         <ul className='text-gray-500 text-lg mb-5'>
                         <li>
                              El tiempo que tenemos una relación continúa con usted en la que le hayamos ofrecido servicios (por ejemplo, el tiempo en que haya tenido una cuenta con nosotros o haya 
                              usado nuestros servicios);
                         </li>
                         <li>
                              Si existe una obligación legal que nos concierna (por ejemplo, ciertas leyes nos exigen conservar registros de sus transacciones durante un determinado tiempo antes de poder 
                              eliminarlos);
                         </li>
                         </ul>
                         <h3 className='text-xl font-semibold mb-5'>Acceso a los servicios para menores de edad:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Nuestros servicios no son adecuados para aquellas personas que se encuentren por debajo de la edad legal para tener un Registro Fiscal de Contribuyentes. 
                         No recopilamos, al menos conscientemente, información sobre dichas personas.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Servicios de terceros:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Esta Política de privacidad no trata, y no nos consideramos responsables, de la privacidad, información y otros métodos de cualquier tercero, entre los que se incluye 
                         cualquier tercero que gestione un sitio web o servicio vinculado a los nuestros.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Datos personales confidenciales:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Le pedimos que no nos envíe ni revele cualquier tipo de información personal confidencial (p. ej., números de seguro social, información sobre su origen racial o étnico, 
                         opiniones políticas o cualquier otro tipo de información relacionada con creencias, salud, biometría, genética, historial criminal o afiliación a un sindicato) en nuestros 
                         servicios o mediante ellos o directamente a nosotros.
                         </p>
                         <h3 className='text-xl font-semibold mb-5'>Actualizaciones a esta política de privacidad:</h3>
                         <p className='text-gray-500 text-lg mb-5'>
                         Es posible que modifiquemos esta Política de privacidad. La leyenda de “Última actualización” al principio de esta Política de privacidad indica cuándo se revisó esta 
                         política por última vez. Cualquier cambio se hará efectivo en el momento en que publiquemos la Política de privacidad revisada en los servicios. Usar los servicios tras 
                         estos cambios implicará la aceptación de la Política de privacidad revisada.
                         </p>
                         </div>
                    </div>
                    <div>
                         <h2 className='text-2xl font-semibold mb-5'>Contacto</h2>
                         <p className='text-gray-500 text-lg mb-5'>
                         En caso de que tenga cualquier pregunta sobre de este Aviso de privacidad, contáctenos vía correo electrónico a: foverbagh@foverbagh.com.mx, por teléfono fijo a los números: (55) 5393 7425, (55) 5562 0643, (55) 5393 2886.
                         </p>
                    </div>
               </div>
          </div>
     );
}