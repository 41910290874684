// components
import Navbar from './components/global/Navbar';
import NavbarDynamic from './components/global/NavbarDynamic';
import Footer from './components/global/Footer';
// react router
import { Route, Routes } from 'react-router-dom';
// pages 
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import BlogArticle from './pages/BlogArticle';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';

export default function App() {
     return(
          <div className='App'>
               <Navbar />
               <NavbarDynamic />
               <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/:id' element={<BlogArticle />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
               </Routes>
               <Footer />
          </div>
     );
}