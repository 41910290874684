// components
import Navbar from '../components/global/Navbar';
import ContactPageMain from '../components/contact/ContactPageMain';
import ContactPageForm from '../components/contact/ContactPageForm';

export default function Contact() {
     return(
          <div>
               <Navbar />
               <ContactPageMain />
               <ContactPageForm />
          </div>
     );
}