// framer motion 
import { AnimatePresence, motion } from 'framer-motion'; 
// react router
import { Link } from 'react-router-dom';
// hooks 
import { useState, useEffect } from 'react';
// media
import logo from '../../assets/logo-red.png';
// icons 
import { Bars3Icon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline'; 
// components
import DropdownMenuModal from '../../components/global/DropdownMenuModal';

export default function NavbarDynamic() {

     const [show, setShow] = useState(false);
     const [lastScrollY, setLastScrollY] = useState(0);
     const [modalIsOpened, setModalIsOpened] = useState(false);

     const controlNavbar = () => {
          if (typeof window !== 'undefined') {
               if (window.scrollY > 500) { // if scroll down 500 px show the navbar
                    setShow(true);
               } else { // if scroll up hide the navbar
                    setShow(false);
               }
               setLastScrollY(window.scrollY); // save position to state
          }
     }

     useEffect(() => {
          if (typeof window !== 'undefined') {
               window.addEventListener('scroll', controlNavbar);
               // cleanup function
               return () => {
                    window.removeEventListener('scroll', controlNavbar);
               }
          }
     }, [lastScrollY]);

     return(
          <AnimatePresence>
               {
                    show && (
                         <motion.div 
                         className='w-screen h-[50px] shadow-lg flex justify-between items-center py-10 px-10 xl:px-64 select-none fixed top-0 z-[1000] bg-white'
                         initial={{ y: -100 }} animate={{ y: 0 }} transition={{ duration: 0.5 }} exit={{y: -100}}
                         >
                              {/* logo section */}
                              <div className='w-36 h-[50px] shrink-0'>
                                   <Link to='/'>
                                        <img src={logo} alt="Foverbagh logo" className='w-20 h-full object-cover' />
                                   </Link>
                              </div>
                              {/* nav section */}
                              <div className='hidden md:flex gap-x-10 text-lg text-black font-semibold'>
                                   <span className='hover:text-red-500'>
                                        <Link to='/'>
                                             Inicio
                                        </Link>
                                   </span>
                                   <span className='hover:text-red-500'>
                                        <Link to='/about'>
                                             Nosotros
                                        </Link>
                                   </span>
                                   <span className='hover:text-red-500'>
                                        <Link to='/services'>
                                             Servicios
                                        </Link>
                                   </span>
                                   <span className='hover:text-red-500'>
                                        <Link to='/blog'>
                                             Blog
                                        </Link>
                                   </span>
                                   <span className='hover:text-red-500'>
                                        <Link to='/contact'>
                                             Contacto
                                        </Link>
                                   </span>
                              </div>
                              {/* dropdown menu icon */}
                              <div className='md:hidden text-black'>
                                   <Bars3Icon 
                                   className='w-10 hover:text-red-500 cursor-pointer'
                                   onClick={() => setModalIsOpened(true)}
                                   />
                              </div>
                              {/* dropdown menu modal */}
                              <AnimatePresence>
                                   {
                                        modalIsOpened && (
                                             <motion.div
                                             className='fixed h-screen w-[50%] bg-white top-0 right-0 z-[10000] flex justify-center shadow-lg'
                                             initial={{ x: 500 }}
                                             animate={{ x: 0 }}
                                             exit={{ x: 500 }}
                                             transition={{ duration: 0.5 }}
                                             onMouseLeave={() => setModalIsOpened(false)}
                                             >
                                                  {/* x mark icon */}
                                                  <XMarkIcon
                                                  className='absolute w-10 top-5 right-5 hover:text-red-500 cursor-pointer'
                                                  onClick={() => setModalIsOpened(false)}
                                                  />
                                                  {/* navs */}
                                                  <div className='h-full w-full flex flex-col justify-center'>
                                                       <DropdownMenuModal />
                                                  </div>
                                             </motion.div>
                                        )
                                   }
                              </AnimatePresence>
                         </motion.div>
                    )
               }
          </AnimatePresence>
     );
}