// react router 
import { useParams } from 'react-router-dom';
// components 
import Article from '../components/blog/Article';

export default function BlogArticle() {
     const { id } = useParams();

     return(
          <Article postId={id} />
     );
}