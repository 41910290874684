// components
import ServicesPageMain from '../components/services/ServicesPageMain';
import ServicesPageTable from '../components/services/ServicesPageTable';

export default function Services() {
     return(
          <div>
               <ServicesPageMain />
               <ServicesPageTable />
          </div>
     );
}