// axios
import axios from 'axios';
// components
import BlogPost from './BlogPost';
import BlogPostMiniature from './BlogPostMiniature';
// hooks 
import { useEffect, useState } from 'react';

export default function BlogPostList() {

     const [data, setData] = useState([]);

     useEffect(() => {
          axios.get(`https://www.googleapis.com/blogger/v3/blogs/${process.env.REACT_APP_BLOG_ID}/posts?key=${process.env.REACT_APP_API_KEY}`)
          .then((res) => {
               setData(res.data.items);
          })
          .catch((err) => console.log(err));
     }, []);

     return(
          <div className='w-screen px-7 md:px-16 2xl:px-64 py-16 lg:py-48'>
               <div className='flex flex-col-reverse xl:flex-row justify-between'>
                    {/* blog post list */}
                    <div className='w-full flex flex-col w-full xl:w-[65%]'>
                         <div className='flex flex-col gap-y-16'>
                              {
                                   data.map((item) => (
                                        <BlogPost 
                                             key={item.id}
                                             id={item.id}
                                             title={item.title}
                                             content={item.content}
                                        />
                                   ))
                              }
                         </div>
                    </div>
                    {/* side section */}
                    <div className='w-full hidden xl:block xl:w-[30%] bg-white p-10 shadow-lg mb-20 xl:mb-0'>
                         {/* recent posts */}
                         <div>
                              <h2 className='font-semibold text-xl mb-5'>Recientes</h2>
                              <div>
                                   {
                                        data.map((item) => (
                                             <BlogPostMiniature 
                                                  key={item.id}
                                                  id={item.id}
                                                  title={item.title}
                                                  content={item.content}
                                                  tag={item.labels[0]}
                                                  date={item.published}
                                             />
                                        ))
                                   }
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
}