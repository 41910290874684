// react router 
import { Link } from 'react-router-dom';
// hooks 
import { useState, useEffect } from 'react';

export default function HomeContact() {

     const [name, setName] = useState('');
     const [email, setEmail] = useState('');
     const [title, setTitle] = useState('');
     const [phone, setPhone] = useState('');
     const [message, setMessage] = useState('');
 
     const [phoneValidation, setPhoneValidation] = useState(false);
 
     // validate phone number
     const validatePhoneNumber = (num) => {
         const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
         return re.test(num);
     }

     useEffect(() => {
          setPhoneValidation(validatePhoneNumber(phone));
      }, [phone]);

     // submit form
     const handleSubmit = (e) => {
          e.preventDefault();
          if (phoneValidation) {
               fetch("https://formsubmit.co/ajax/foverbagh@foverbagh.com", {
                   method: "POST",
                   headers: { 
                       'Content-Type': 'application/json',
                       'Accept': 'application/json'
                   },
                   body: JSON.stringify({
                       name,
                       email,
                       phone,
                       title,
                       message,
                   })
               })
               .then(response => response.json())
               .then(() => console.log(validatePhoneNumber(phone)))
               .then(() => alert('¡Envío exitoso!'))
               .catch(error => console.log(error));
           } else {
               alert('El número telefónico no es válido.');
           }
     }

     return(
          <div className='w-full flex 2xl:flex-row flex-col gap-y-32 justify-center items-center gap-x-20 px-10 xl:p-32 2xl:px-64 py-36'>
               {/* left text */}
               <div className='flex flex-col gap-y-5 justify-center items-center'>
                    <h2 className='text-4xl sm:text-6xl font-semibold mb-10 text-center'>Conoce toda la cartera de servicios que ofrecemos en Foverbagh</h2>
                    <button className='bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg cursor-pointer shadow-lg'>
                         <Link to='/services'>
                              Comencemos
                         </Link>
                    </button>
               </div>
               {/* form */}
               <form className='w-full' onSubmit={handleSubmit} autoComplete="off">
                    <div className='w-full'>
                        <div className='flex flex-col md:flex-row justify-between'>
                            {/* name */}
                            <input required maxLength="50" type="text" placeholder='Nombre Completo*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='name' onChange={(e) => setName(e.target.value)} />
                            {/* email */}
                            <input required maxLength="50" type="email" placeholder='Email*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='email' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='flex flex-col md:flex-row justify-between'>
                            {/* title */}
                            <input required maxLength="100" type="text" placeholder='Motivo*' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' name='title' onChange={(e) => setTitle(e.target.value)} />
                            {/* phone number */}
                            <input required type="text" placeholder='Número de teléfono' className='w-full mb-5 border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' maxLength="20" name='phone' onChange={(e) => setPhone(e.target.value)} />
                        </div>
                    </div>
                    {/* message */}
                    <textarea className='w-full border-b border-gray-300 p-5 text-xl focus:outline-none focus:placeholder-black focus:border-black hover:bg-gray-100' placeholder='Mensaje' cols="30" rows="10" name='message' maxLength="1000" required onChange={(e) => setMessage(e.target.value)}></textarea>
                    {/* button */}
                    <button className='mt-10 w-full bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg' type='submit'>Enviar</button>
                </form>
          </div>
     );
} 