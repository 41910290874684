// utils 
import { cleanTextShort, getImageSrc } from '../../utils/blog';
// icons
import { ArrowRightIcon } from '@heroicons/react/24/outline';
// react router
import { Link } from 'react-router-dom';

export default function BlogPost({ id, title, content }) {

     const cleanText = cleanTextShort(content);
     const imgUrl = getImageSrc(content);

     return(
          <div className='w-full flex flex-col gap-y-5'>
               {/* embedded img (if any) */}
               {
                    imgUrl && 
                    <img className='w-full h-[500px] object-cover shadow-lg' src={imgUrl} alt='' />
               }
               <h2 className='w-full text-5xl font-semibold hover:text-sky-400'>
                    <Link to={`/blog/${id}`}>
                         {title}
                    </Link>
               </h2>
               {/* content */}
               <div>
                    {/* clean text cut to 50 characters */}
                    <p className='w-full text-xl text-gray-500 font-light'>
                         {cleanText}
                    </p>
               </div>
               <div className='w-5 cursor-pointer hover:text-sky-400 transition-colors'>
                    <Link to={`/blog/${id}`}>
                         <ArrowRightIcon />
                    </Link>
               </div>
          </div>
     );
}