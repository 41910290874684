// utils
import { getImageSrc, formatDate } from '../../utils/blog';
// react router
import { Link } from 'react-router-dom';

export default function BlogPostMiniature({ id, title, content, tag, date }) {

     const imgUrl = getImageSrc(content);
     const formattedDate = formatDate(date);
     const tagToUpperCase = tag.toUpperCase();

     return(
          <div className='flex items-center gap-y-3 gap-x-3'>
               {/* embedded img (if any) */}
               {
                    imgUrl && 
                    <img src={imgUrl} alt="" className='w-[30%] h-[30%] object-cover' />
               }
               <div>
                    <div className='flex justify-between'>
                         <span className='text-xs font-semibold text-gray-500'>{tagToUpperCase}</span>
                         <span className='text-xs font-semibold text-gray-500'>•</span>
                         <span className='text-xs font-semibold text-gray-500'>{formattedDate}</span>
                    </div>
                    <div className='text-xl hover:text-sky-400 font-semibold'>
                         <Link to={`/blog/${id}`}>
                              <span>{title}</span>
                         </Link>
                    </div>
               </div>
          </div>
     );
}