// components
import img from '../../assets/NOSOTROS-portada-foverbagh.webp';
// icons 
import { ChevronDownIcon } from '@heroicons/react/24/outline';
// framer motion
import { motion } from 'framer-motion';
 
export default function AboutPageMain() {
     return(
          <div className='relative w-screen h-screen'>
               <div className='w-full h-full absolute flex items-center justify-center'>
                    {/* text */}
                    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1.5 }} className='w-[80%] md:w-[60%] flex flex-col gap-y-5 justify-center items-center p-5'>
                         <h1 className='font-semibold text-4xl sm:text-5xl md:text-7xl text-white text-center'>Conócenos</h1>
                         <ChevronDownIcon className='w-5 text-white text-center' />
                    </motion.div>
               </div>
               {/* image */}
               <div className='w-screen h-screen'>
                    <img className='w-full h-full object-cover' src={img} alt="" />
               </div>
          </div>
     );
}