// media 
import img1 from '../../assets/angel-independencia.webp';
import img2 from '../../assets/NOSOTROS.webp';

export default function AboutPageGeneral() {
     return(
          <div className='relative w-screen flex flex-col xl:flex-row px-10 md:px-20 py-20 md:py-40 bg-red-50 items-center gap-y-20'>
               {/* text */}
               <div className='w-full xl:w-[40%] flex flex-col justify-center items-center'>
                    <div className='md:w-[60%] flex flex-col gap-y-5'>
                         <h3 className='text-xl text-red-900'>SERVICIOS LEGALES DE EXCELENCIA</h3>
                         <h2 className='text-4xl md:text-5xl font-semibold'>Perfil de la firma</h2>
                         <p className='text-gray-600 text-xl font-light text-justify'>
                              Es un despacho altamente especializado en materia penal, lo que nos permite realizar estrategias integrales para nuestros clientes.
                         </p>
                         <p className='text-gray-600 text-xl font-light text-justify'>
                              Tenemos un amplio conocimiento de los instrumentos legales inherentes al sistema de Justicia Penal (Tradicional y Acusatorio), pues nuestros socios y asociados cuentan con amplia trayectoria profesional tanto en el sector público como privado.
                         </p>
                    </div>
               </div>
               {/* images */}
               <div className='w-full w-[50%] xl:w-[60%] relative h-[900px]'>
                    {/* image behind */}
                    <div className='w-[70%]'>
                         <img src={img1} alt="" className='object-cover w-[70%] h-[500px] absolute z-[5] top-0 right-0 shadow-lg' />
                    </div>
                    {/* image on top */}
                    <div className='w-[70%]'>
                         <img src={img2} alt="" className='object-cover w-[70%] h-[500px] absolute z-[6] bottom-0 left-0 shadow-lg' />
                    </div>
               </div>
          </div>
     );
}