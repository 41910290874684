// media
import mission from '../../assets/icons8-lo-que-hago-100.png';
import vision from '../../assets/icons8-objetivo-100.png';

export default function AboutPageMission() {
     return(
          <div className='w-screen flex flex-col lg:flex-row justify-center items-start px-10 sm:px-20 lg:px-0 py-20 gap-x-10'>
               {/* mission */}
               <div className='w-full lg:w-[40%] p-10 flex flex-col gap-y-5 items-center'>
                    <img className='w-36' src={mission} alt="" />
                    <h2 className='text-5xl font-semibold mb-5'>Misión</h2>
                    <p className='text-gray-600 text-justify text-xl font-light'>
                         Prestar servicios legales de excelencia, donde nuestros clientes tengan plena certeza de que su asunto nos importa y nos ocupa, partiendo de una visión humanista del derecho.
                    </p>
                    <p className='text-gray-600 text-justify text-xl font-light'>
                         Ello a partir de rigurosos procesos de análisis que permitan plantear estrategias y ofrecer soluciones integrales de acuerdo a las necesidades de cada uno de nuestros clientes.
                    </p>
               </div>
               {/* vision */}
               <div className='w-full lg:w-[40%] p-10 flex flex-col gap-y-5 items-center'>
                    <img className='w-36' src={vision} alt="" />
                    <h2 className='text-5xl font-semibold mb-5'>Visión</h2>
                    <p className='text-gray-600 text-justify text-xl font-light'>
                         En Foverbagh la firma visualiza su desempeño como un compromiso que:
                    </p>
                    <p className='text-gray-600 text-justify text-xl font-light'>
                         Permita a sus clientes, desde el primer contacto, contar con un profesional del derecho, que los asesore y le brinde innovadoras y oportunas soluciones integrales, con una asesoría preventiva y estratégica. Prestar permanentemente servicios legales de calidad con valores y responsabilidad social, que asegure servir con excelencia a los demás.
                    </p>
               </div>
          </div>
     );
}