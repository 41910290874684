// hooks
import { useState } from 'react';
// icons 
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

export default function ServicesPageTable() {

     const [dropIsOpen1, setDropIsOpen1] = useState(false);
     const [dropIsOpen2, setDropIsOpen2] = useState(false);
     const [dropIsOpen3, setDropIsOpen3] = useState(false);
     const [dropIsOpen4, setDropIsOpen4] = useState(false);
     const [dropIsOpen5, setDropIsOpen5] = useState(false);
     const [dropIsOpen6, setDropIsOpen6] = useState(false);
     const [dropIsOpen7, setDropIsOpen7] = useState(false);
     const [dropIsOpen8, setDropIsOpen8] = useState(false);

     // handle drop 1 
     const handleDrop1 = (e) => {
          e.preventDefault();
          if (dropIsOpen1) {
               setDropIsOpen1(false);
          } else if (!dropIsOpen1) {
               setDropIsOpen1(true);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }
     
     const handleDrop2 = (e) => {
          e.preventDefault();
          if (dropIsOpen2) {
               setDropIsOpen2(false);
          } else if (!dropIsOpen2) {
               setDropIsOpen1(false);
               setDropIsOpen2(true);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }

     const handleDrop3 = (e) => {
          e.preventDefault();
          if (dropIsOpen3) {
               setDropIsOpen3(false);
          } else if (!dropIsOpen3) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(true);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }

     const handleDrop4 = (e) => {
          e.preventDefault();
          if (dropIsOpen4) {
               setDropIsOpen4(false);
          } else if (!dropIsOpen4) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(true);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }

     const handleDrop5 = (e) => {
          e.preventDefault();
          if (dropIsOpen5) {
               setDropIsOpen5(false);
          } else if (!dropIsOpen5) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(true);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }

     const handleDrop6 = (e) => {
          e.preventDefault();
          if (dropIsOpen6) {
               setDropIsOpen6(false);
          } else if (!dropIsOpen6) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(true);
               setDropIsOpen7(false);
               setDropIsOpen8(false);
          }
     }

     const handleDrop7 = (e) => {
          e.preventDefault();
          if (dropIsOpen7) {
               setDropIsOpen7(false);
          } else if (!dropIsOpen7) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(true);
               setDropIsOpen8(false);
          }
     }

     const handleDrop8 = (e) => {
          e.preventDefault();
          if (dropIsOpen8) {
               setDropIsOpen8(false);
          } else if (!dropIsOpen8) {
               setDropIsOpen1(false);
               setDropIsOpen2(false);
               setDropIsOpen3(false);
               setDropIsOpen4(false);
               setDropIsOpen5(false);
               setDropIsOpen6(false);
               setDropIsOpen7(false);
               setDropIsOpen8(true);
          }
     }

     return(
          <div className='w-screen flex flex-col gap-y-20 justify-center gap-x-20 py-20 px-10 lg:px-20'>
               <div className='w-full h-auto flex flex-col items-center justify-center z-[10]'>
                    {/* dropdown 1 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop1} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen1 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Administrativo</h2>
                              { dropIsOpen1 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen1 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        Ante la creciente regulación de las relaciones y de las actividades entre el Estado y los particulares, nuestra firma, se ha enfocado en el estudio de dicha regulación para implementar esquemas preventivos que permitan a nuestros clientes cumplir con los requisitos que, en los diferentes niveles de gobierno, son establecidos para controlar el desarrollo de una actividad comercial.
                                   </p>
                                   <p>
                                        Contamos con especialistas para hacer frente a aquellos actos de la Administración Pública que pueden afectar al particular en su actividad y que incluso, requieren de una acción para obtener la reparación del daño derivado de la actividad irregular del Estado.
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Gestiones en procedimientos administrativos antes las Secretarías de Estado</li>
                                        <li>Asesoría y gestión ante autoridades administrativas locales y federales en la obtención de autorizaciones</li>
                                        <li>Asesoría y gestión ante autoridades administrativas, locales y federales en la obtención de todo tipo de licencias</li>
                                        <li>Atención de procedimientos desarrollados antes PROFECO y CONDUSEF</li>
                                        <li>Asesoría y revisión en cumplimiento de normas oficiales</li>
                                        <li>Asesoría y gestión en materia de concesiones</li>
                                        <li>Defensoría de Servidores Públicos</li>
                                        <li>Interposición de acciones por responsabilidad patrimonial del Estado</li>
                                        <li>Interposición de medios de defensa contra resoluciones administrativas</li>
                                        <li>Defensa en juicios contrala autoridad administrativa</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 2 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop2} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen2 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Arbitraje y mediación</h2>
                              { dropIsOpen2 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen2 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        La implementación de Medios Alternativos de Solución de Controversias es cada vez mas común, lo cual responde a una sociedad más organizada y consiente de las implicaciones de sus relaciones jurídicas.
                                   </p>
                                   <p>
                                        Contamos cuenta con amplia experiencia en materia de Arbitraje, con abogados que han participado en diversos procedimientos arbitrales, entre los que figuran los sostenidos entre empresas productivas del estado y el sector privado en materia energética, de infraestructura, construcción, generación de energía, transporte de gas natural, conflictos entre accionistas, fusiones, escisiones, cuestiones inmobiliarias, así como casos de Caso Fortuito o Fuerza Mayor, entre otras; ante diversas instituciones internacionales como lo son la International Chamber of Commerce (ICC), la London Court of International Arbitration (LCIA), así como instituciones Nacionales como la Cámara Nacional de Comercio de la Ciudad de México CANACO y el Centro de Mediación Arbitraje y Conciliación (CEMAC).
                                   </p>
                                   <p>
                                        Del mismo modo, somos conscientes que en ocasiones, cualquier tipo de controversia puede ser perjudicial para los negocios o empresas, por lo cual pone a disposición de sus clientes el servicio de Mediación, para forma través de esta herramienta buscar el mejor acuerdo.
                                   </p>
                                   <p>
                                        Contamos con un equipo de trabajo y empleo reconocido a nivel nacional que asesora a los clientes sobre cómo establecer estructuras legales sólidas que cumplan con los requisitos reglamentarios, lo que minimiza el riesgo de disputas laborales. Asistimos a grandes empresas transnacionales y medianas con este tipo de disputas.
                                   </p>
                                   <p>
                                        Nuestro equipo emplea un enfoque preventivo y coordinado utilizando estrategias personalizadas para optimizar el material y los recursos humanos de los clientes mediante el aprovechamiento de nuestras habilidades especializadas en resolución de conflictos de trabajo y empleo.
                                   </p>
                              </div>
                         }
                    </div>
                    {/* dropdown 3 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop3} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen3 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Corporativo</h2>
                              { dropIsOpen3 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen3 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        Contamos con profesionales capacitados para acompañar y ayudar a las empresas en su organización, su constitución, la de sus órganos, las limitaciones a facultades, la planeación para su crecimiento y el asesoramiento para un mejor funcionamiento, así como para la celebración de diversos contratos como lo son: de asociación, de joint venture, de franquicia; por estas razones, dentro de nuestros servicios se encuentra:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Asesoría, consultoría, planeación y elaboración de proyectos relativos a la constitución de sociedades mercantiles</li>
                                        <li>Elaboración, control y archivo de acciones, libros y demás documentos propios para el mejor funcionamiento de una sociedad</li>
                                        <li>Elaboración de todo tipo de actas de asambleas, junta de socios, consejo de administración y resoluciones unánimes entre otras</li>
                                        <li>Asesoría, consultoría, planeación y elaboración de proyectos de fusión, escisión, transformación, disolución y liquidación de sociedades, con la correspondiente auditoria legal de las empresas</li>
                                        <li>Elaboración de contratos de donación, compraventa, cesión de derechos en lo relativo a las actividades de la empresa y sujetos relacionados</li>
                                        <li>Tramitación, gestión y revisión de los proyectos para la formalización de las diversas operaciones como lo son la protocolización de actas de asamblea, de poderes otorgados en el extranjero, la ratificación de firmas, etc.</li>
                                        <li>Estructuración y elaboración de contratos de fideicomiso</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 4 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop4} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen4 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Familiar</h2>
                              { dropIsOpen4 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen4 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        La familia es una institución cuya configuración, concepción y evolución ha evolucionado y tiene una gran trascendencia en el ámbito social pues es uno de los pilares de nuestra sociedad, por ello, dentro de los servicios que ofrecemos se encuentran:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Asesoría y elaboración de las capitulaciones matrimoniales</li>
                                        <li>Asesoramiento en la administración de los bienes propios de los cónyuges,</li>
                                        <li>creación del patrimonio de familia</li>
                                        <li>Tramitación de pensiones alimenticias</li>
                                        <li>Tramitación del juicio de divorcio</li>
                                        <li>Tramitación del juicio de guarda y custodia, patria potestad</li>
                                        <li>Tramitación de liquidación de la sociedad conyugal</li>
                                        <li>Asesoría, consultoría y tramitación de los procedimientos sucesorios, testamentarios o intestamentarios</li>
                                        <li>Asesoría, consultoría, y acompañamiento de los trámites sucesorios de procedimientos sucesorios extrajudiciales</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 5 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop5} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen5 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Fiscal</h2>
                              { dropIsOpen5 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen5 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        A través de un análisis de las características y situaciones especiales de cada contribuyente, al amparo de las diversas leyes fiscales desarrolla un plan de trabajo que optimice sus recursos. Dentro de los servicios que ofrecemos, se encuentran:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Asesoría en la aplicación de leyes impositivas</li>
                                        <li>Revisión periódica de las obligaciones contables y fiscales de la empresa, para evitar diferencias con las autoridades fiscales que podrían generar multas y recargos</li>
                                        <li>Gestiones ante el SAT</li>
                                        <li>Gestiones ante el IMSS</li>
                                        <li>Gestiones ante el INFONAVIT</li>
                                        <li>Descongelamiento de cuentas bancarias embargadas por autoridades fiscales</li>
                                        <li>Asesoría en relación a impuestos federales y locales omitidos</li>
                                        <li>Atención de auditorías iniciadas por autoridades fiscales</li>
                                        <li>Asesoría en materia de acuerdos conclusivos ante la PRODECON</li>
                                        <li>Defensa y asesoría con motivo de la suscripción de convenios de pago en parcialidades</li>
                                        <li>Interposición del Recurso de Inconformidad ante el IMSS y el INFONAVIT</li>
                                        <li>Recuperación de Saldos a Favor de Impuestos</li>
                                        <li>Recurso de Revocación ante el SAT</li>
                                        <li>Juicio de Nulidad en contra de determinaciones de impuestos federales y locales</li>
                                        <li>Juicio de Nulidad en multas fiscales federales y locales</li>
                                        <li>Amparo contra leyes fiscales y actos emitidos por autoridades fiscales federales o locales</li>
                                        <li>Juicio de Nulidad en contra de determinaciones emitidas en procedimientos administrativos en materia aduanera</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 6 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop6} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen6 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Laboral</h2>
                              { dropIsOpen6 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen6 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        En materia laboral nuestros servicios se enfocan en no solo en la atención a la corrección de problemas, sino también se enfoca en la cuestión preventiva, llevando a cabo todos aquellos actos que sean necesarios para lograr un correcto manejo de recursos materiales, así como de la plantilla de empleados, lo cual se realiza a fin de evitar litigios entre patrón- trabajador.
                                   </p>
                                   <p>
                                        Entre los servicios que ofrecemos se encuentran:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Auditorías laborales</li>
                                        <li>Atención, preparación y solución de inspecciones sobre condiciones generales de trabajo</li>
                                        <li>Procedimientos de contratación y terminación de la relación de trabajo entre patrones y sus trabajadores</li>
                                        <li>Integración y en su caso supervisión de expedientes personales de trabajadores, que incluye investigaciones socioeconómicas, de antecedentes laborales, de conducta laboral, referencias personales, entre otros</li>
                                        <li>Atención y solución de quejas antes las Procuradurías de la Defensa del Trabajo</li>
                                        <li>Asesoría en contratación colectiva, revisiones contractuales, emplazamientos a huelga, conflictos colectivos de naturaleza jurídica y económica</li>
                                        <li>Integración y en su caso supervisión, de las Comisiones Mixtas de capacitación y adiestramiento y de seguridad e Higiene; de reparto de utilidades, de cuadro general de antigüedades, así como en la elaboración o en su caso en la revisión y actualización del Reglamento Interior de Trabajo</li>
                                        <li>Impartición de cursos y pláticas tanto informativas como preventivas a personal estratégico</li>
                                        <li>Área contenciosa laboral (demandas):</li>
                                        <li>Preparación de estrategias procesales para enfrentar un Juicio</li>
                                        <li>Recabar documentación necesaria para comparecer a defender sus intereses en Juicio</li>
                                        <li>Asistencia de un especialista a la etapa conciliatoria dentro del Juicio laboral, con el fin de procurar concluirlo sin agotar todo el proceso</li>
                                        <li>Contestación de demandas, ofrecimiento y desahogo de pruebas ante la Junta de conciliación y arbitraje</li>
                                        <li>Asistencia de un especialista en todas las etapas procesales del Juicio laboral</li>
                                        <li>Elaboración de Amparos</li>
                                        <li>Asesoría jurídica a víctimas</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 7 */}
                    <div className='h-auto w-full'>
                         <div onClick={handleDrop7} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen7 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Litigio civil y mercantil</h2>
                              { dropIsOpen7 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen7 && 
                              <div className='px-10 font-light text-justify text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        Contamos con especialistas para brindar servicios en relación con la celebración de diversos contratos, de la transmisión de bienes o mercancías, así como para defender los derechos de nuestros clientes, a través de:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Asesoría, consultoría, negociación y elaboración de diversos talos como contratos de compra-venta, de renta vitalicia, de compra de esperanza, de donación, de permuta, de arrendamiento, de mutuo, de comodato, de depósito, de prestación de servicios profesionales, de prenda, de hipoteca, entre otros.
                                        </li>
                                        <li>Asesoría, planeación, seguimiento, defensa y representación en todo tipo de procedimientos judiciales</li>
                                        <li>Asesoría, consultoría, y gestión en procedimientos, juicios y diligencias como el Apeo y deslinde, prescripciones adquisitivas, los procesos reivindicatorios, la inmatriculación judicial, contratos de copropiedad, cuestiones relativas a usufructos, servidumbres y demás relativas</li>
                                        <li>Asesoría y consultoría para la constitución de Regímenes de Propiedad en Condominio</li>
                                        <li>Asesoría, planeación y constitución de garantías reales o personales, como los son la hipoteca, la prenda, su registro, ampliación, división, extinción etc.</li>
                                        <li>Asesoría, planeación, negociación, y elaboración de los contratos y convenios de fideicomiso, depósito y garantía, arrendamiento financiero, corretaje, entre otros ya sea con particulares o con sectores de carácter público</li>
                                        <li>Asesoría, consultoría planeación y elaboración de proyectos relativos a los contratos de franquicia, joint venture, asociación en participación o bien contratos de exclusividad</li>
                                        <li>Asesoría, planeación representación, defensa y seguimiento de todo tipo de procedimientos y litigios comerciales, de quiebras, suspensiones de pago, etc.</li>
                                   </ul>
                              </div>
                         }
                    </div>
                    {/* dropdown 8 */}
                    <div className='h-auto w-full border-b border-black'>
                         <div onClick={handleDrop8} className={`cursor-pointer w-full flex justify-between border-x border-t border-black px-10 hover:text-white hover:bg-red-900 ${dropIsOpen8 ? 'bg-red-900 text-white' : ''}`}>
                              <h2 className='font-semibold text-lg sm:text-2xl py-3'>Litigio penal</h2>
                              { dropIsOpen8 ? <ChevronUpIcon className='w-7' /> : <ChevronDownIcon className='w-7' /> }
                         </div>
                         {
                              dropIsOpen8 && 
                              <div className='px-10 font-light text-justify sm:text-md flex flex-col gap-y-5 border-x border-black py-5'>
                                   <p>
                                        Materia penal es una de las áreas con mayor incidencia en nuestra vida diaria, para lo cual el despacho cuenta con un grupo de especialistas que se encargan de analizar caso por caso desde un punto de vista integral para lograr la defensa de los bienes jurídicos tutelados de nuestros clientes, entre nuestros servicios se encuentran:
                                   </p>
                                   <ul className='list-disc'>
                                        <li>Asesoría jurídica a víctimas</li>
                                        <li>Consultoría penal</li>
                                        <li>Defensa penal</li>
                                        <li>Amparo penal</li>
                                        <li>Planeación estratégica en materia penal</li>
                                   </ul>
                              </div>
                         }
                    </div>
               </div>
          </div>
     );
} 