// react html parser
import parse from 'html-react-parser';
// hooks 
import { useState, useEffect } from 'react';
// axios
import axios from 'axios';
// utils 
import { getImageSrc, cleanText } from '../../utils/blog';
// framer motion
import { motion } from 'framer-motion';

export default function Article({ postId }) {

     const [ data, setData ] = useState([]);

     useEffect(() => {
          axios.get(`https://www.googleapis.com/blogger/v3/blogs/${process.env.REACT_APP_BLOG_ID}/posts?key=${process.env.REACT_APP_API_KEY}`)
          .then((res) => {
               setData(res.data.items);
          })
          .catch((err) => console.log(err));
     }, []);

     let article = data?.filter(article => article.id == postId);
     article = article[0];
     let content = article ? article.content : '';
     let cleanContent = content ? cleanText(content) : '';
     let imgSrc = content ? getImageSrc(content) : '';

     return(
          <div className='w-screen'>
               <div className='relative w-full h-[700px]'>
                    {
                         imgSrc && 
                         <img src={imgSrc} alt="" className='w-full h-full object-cover absolute z-0' />
                    }
                    <div className='w-full h-full flex items-center justify-center bg-black'>
                         <h1 className='text-2xl lg:text-6xl text-center font-semibold mb-10 absolute z-1 text-white top-[45%] bg-black p-5 bg-opacity-50'>{article?.title}</h1>
                    </div>
               </div>
               <div className='w-full px-7 md:px-16 2xl:px-64 pt-10 pb-32'>
                    {cleanContent ? parse(cleanContent) : ''}
               </div>
               {/* override styles */}
               <style jsx global>{`
                    h3 {
                        font-weight: bold;
                        font-size: 1.8rem;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                    p {
                        color: gray;
                        font-weight: 300;
                        font-size: 1.2rem;
                        text-align: justify;
                    }
                `}</style>
          </div>
     );
}