// react router
import { Link } from 'react-router-dom';

export default function DropdownMenuModal() {
     return(
          <div className='flex flex-col text-center font-semibold gap-y-5'>
               <span className='cursor-pointer hover:text-red-500 transition-colors'>
                    <Link to='/'>
                         Inicio
                    </Link>
               </span>
               <span className='cursor-pointer hover:text-red-500 transition-colors'>
                    <Link to='/about'>
                         Nosotros
                    </Link>
               </span>
               <span className='cursor-pointer hover:text-red-500 transition-colors'>
                    <Link to='/services'>
                         Servicios
                    </Link>
               </span>
               <span className='cursor-pointer hover:text-red-500 transition-colors'>
                    <Link to='/blog'>
                         Blog
                    </Link>
               </span>
               <span className='cursor-pointer hover:text-red-500 transition-colors'>
                    <Link to='/contact'>
                         Contacto
                    </Link>
               </span>
          </div>
     );
} 