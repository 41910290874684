// components
import BlogPageMain from '../components/blog/BlogPageMain';
import BlogPostList from '../components/blog/BlogPostList';

export default function Blog() {
     return(
          <div>
               <BlogPageMain />
               <BlogPostList />
          </div>
     );
}