// framer motion
import { motion } from 'framer-motion';
// media 
import img from '../../assets/HOME-1.webp';

export default function HomeDescription() {
     return(
          <div className='w-screen flex flex-col-reverse justify-center items-center md:flex-row shadow-lg pt-20 md:pb-20 lg:pt-40 lg:pb-40'>
            {/* image */}
            <motion.div 
                className='w-full h-full md:w-[50%] flex justify-center items-center'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
            >
                <div className='flex justify-center items-center w-full relative'>
                    <img src={img} className='object-cover w-full md:w-[80%] h-full shadow-lg' alt='' />
                    <div className='bg-black text-white absolute z-[500] w-[50%] h-[50%] bottom-0 right-0 flex items-center justify-center p-10 md:p-5 shadow-lg'>
                        <span className='text-2xl sm:text-6xl md:text-2xl xl:text-3xl font-semibold'>
                            Respaldados por más de 3 décadas de experiencia
                        </span>
                    </div>
                </div>
            </motion.div>
            {/* text */}
            <div className='w-full md:h-full md:w-[50%] flex flex-col justify-center pb-20 md:pb-0'>
                <div className='px-10 lg:pl-0 lg:pr-32 lg:pl-16 2xl:pr-64 flex flex-col'>
                    <h2 className='text-4xl lg:text-5xl font-semibold mb-10'>Despacho penal altamente especializado en materia penal</h2>
                    <p className='text-gray-500 text-md lg:text-lg mb-10'>Nuestra gran trayectoria ha hecho que se nos identifique como uno de los despachos legales más importantes dentro de la República Mexicana.</p>
                </div>
            </div>
        </div>
     );
}