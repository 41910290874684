// media 
import slide1 from '../../assets/SLIDER-1-Foverbagh.webp';
import slide2 from '../../assets/Slider-2-Foverbagh-.webp';
import slide3 from '../../assets/Slider-3-Foverbagh-.webp';
// hooks 
import { useState } from 'react';
// icons
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
// framer motion
import { motion } from 'framer-motion';
// react router
import { Link } from 'react-router-dom';

export default function HomeMain() {

     const [ slideNum, setSlideNum ] = useState(1);

     // move right slides state
     const moveRight = (e) => {
          e.preventDefault();
          if (slideNum === 1) {
               setSlideNum(2);
          } else if (slideNum === 2) {
               setSlideNum(3);
          } else if (slideNum === 3) {
               setSlideNum(1);
          }
     }

     // move left slides state
     const moveLeft = (e) => {
          e.preventDefault();
          if (slideNum === 3) {
               setSlideNum(2);
          } else if (slideNum === 2) {
               setSlideNum(1);
          } else if (slideNum === 1) {
               setSlideNum(3);
          }
     }

     return(
          <div className='w-screen h-screen relative'>
               {/* background image */}
               <div className='w-full h-full z-[9]'>
                    {slideNum === 1 && <img src={slide1} className='w-full h-full object-cover' />}
                    {slideNum === 2 && <img src={slide2} className='w-full h-full object-cover' />}
                    {slideNum === 3 && <img src={slide3} className='w-full h-full object-cover' />}
               </div>
               {/* center text */}
               {
                    slideNum === 1 && 
                    <div className='w-full h-full absolute z-[10] flex justify-center items-center top-0'>
                         <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1.5 }} className='w-full flex flex-col justify-center items-center'>
                              <h1 className='text-2xl sm:text-4xl md:text-5xl xl:text-8xl text-center font-semibold mb-10 select-none text-white w-[50%]'>Excelencia en servicios legales</h1>
                              <button className='bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg cursor-pointer shadow-lg'>
                                <Link to='/about'>
                                    Conoce más
                                </Link>
                            </button>
                         </motion.div>
                    </div>
               }
               {
                    slideNum === 2 && 
                    <div className='w-full h-full absolute z-[10] flex justify-center items-center top-0'>
                         <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1.5 }} className='w-full flex flex-col justify-center items-center'>
                              <h1 className='text-2xl sm:text-4xl md:text-5xl xl:text-8xl text-center font-semibold mb-10 select-none text-white w-[50%]'>Asesoría jurídica profesional</h1>
                              <button className='bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg cursor-pointer shadow-lg'>
                                <Link to='/about'>
                                    Comencemos
                                </Link>
                            </button>
                         </motion.div>
                    </div>
               }
               {
                    slideNum === 3 && 
                    <div className='w-full h-full absolute z-[10] flex justify-center items-center top-0'>
                         <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1.5 }} className='w-full flex flex-col justify-center items-center'>
                              <h1 className='text-2xl sm:text-4xl md:text-5xl xl:text-8xl text-center font-semibold mb-10 select-none text-white w-[50%]'>Servicio profesional brindado por expertos</h1>
                              <button className='bg-red-900 hover:bg-red-500 py-5 px-10 text-white font-semibold select-none shadow-lg cursor-pointer shadow-lg'>
                                <Link to='/about'>
                                    ¡Actualízate!
                                </Link>
                            </button>
                         </motion.div>
                    </div>
               }
               {/* slider button right */}
               <div 
               className='absolute z-10 top-[50%] md:top-[43%] right-0 bg-white px-3 md:px-5 py-5 md:py-10 bg-opacity-70 hover:bg-opacity-50 cursor-pointer'
               onClick={moveRight}
               >
                    <ChevronRightIcon className='w-5 md:w-10' />
               </div>
               {/* slider button left */}
               <div 
               className='absolute z-10 top-[50%] md:top-[43%] left-0 bg-white px-3 md:px-5 py-5 md:py-10 bg-opacity-70 hover:bg-opacity-50 cursor-pointer'
               onClick={moveLeft}
               >
                    <ChevronLeftIcon className='w-5 md:w-10' />
               </div>
               {/* slider dots */}
               <div className='absolute bottom-10 gap-x-3 w-full flex justify-center'>
                    <motion.div className='flex gap-x-3' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
                         {slideNum === 1 ? <div className='h-2 w-2 bg-red-500 rounded-full cursor-pointer'></div> : <div onClick={() => setSlideNum(1)} className='h-2 w-2 bg-white rounded-full cursor-pointer'></div>}
                         {slideNum === 2 ? <div className='h-2 w-2 bg-red-500 rounded-full cursor-pointer'></div> : <div onClick={() => setSlideNum(2)} className='h-2 w-2 bg-white rounded-full cursor-pointer'></div>}
                         {slideNum === 3 ? <div className='h-2 w-2 bg-red-500 rounded-full cursor-pointer'></div> : <div onClick={() => setSlideNum(3)} className='h-2 w-2 bg-white rounded-full cursor-pointer'></div>}
                    </motion.div>
               </div>
          </div>
     );
} 