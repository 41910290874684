// media 
import logo from '../../assets/logo-white.png';
// react router 
import { Link } from 'react-router-dom';

export default function Footer() {
     return(
          <div className='w-screen bg-gradient-to-tr from-red-500 via-red-900 to-red-900 pt-20 px-5 sm:px-10 md:px-20'>
               {/* company info */}
               <div className='w-full flex flex-col md:flex-row gap-x-10 justify-center items-center border-b pb-10'>
                    {/* logo */}
                    <div className='w-[200px] md:w-[30%] shrink-0 flex justify-center mb-10'>
                         <img src={logo} alt="" className='w-[400px] shrink-0' />
                    </div>
                    {/* info */}
                    <div className='md:w-[60%] flex flex-col gap-y-10 md:flex-row justify-center gap-x-20'>
                         {/* navs */}
                         <div className='flex flex-col gap-y-5'>
                              <h2 className='text-2xl text-white font-semibold'>Menú</h2>
                              <Link to='/'>
                                   <span className='font-light text-white text-xl hover:underline'>Inicio</span>
                              </Link>
                              <Link to='/about'>
                                   <span className='font-light text-white text-xl hover:underline'>Nosotros</span>
                              </Link>
                              <Link to='/services'>
                                   <span className='font-light text-white text-xl hover:underline'>Servicios</span>
                              </Link>
                              <Link to='/contact'>
                                   <span className='font-light text-white text-xl hover:underline'>Contacto</span>
                              </Link>
                              <Link to='/blog'>
                                   <span className='font-light text-white text-xl hover:underline'>Blog</span>
                              </Link>

                         </div>
                         {/* contact */}
                         <div className='flex flex-col gap-y-5'>
                              <h2 className='text-2xl text-white font-semibold'>¡Mándanos mensaje!</h2>
                              <span className='text-md sm:text-xl font-light text-white'>foverbagh@foverbagh.com.mx</span>
                              <Link to='/privacy-policy'>
                                   <span className='underline text-xl text-white font-light'>Aviso de Privacidad</span>
                              </Link>
                         </div>
                    </div>
               </div>
               {/* conditions and qu */}
               <div className='w-full flex justify-center items-center py-10'>
                    <p className='text-white font-light'>Todos los derechos reservados por: <span className='font-semibold text-black'>Foverbagh | Corporativo Jurídico</span> @2023 Powered by <span className='font-semibold text-black'>Qü | Enhance Your Reality</span></p>
               </div>
          </div>
     );
}