// components
import HomeMain from '../components/home/HomeMain';
import HomeDescription from '../components/home/HomeDescription';
import HomeServices from '../components/home/HomeServices';
import HomeContact from '../components/home/HomeContact';

export default function Home() {
     return(
          <div>
               <HomeMain />
               <HomeDescription />
               <HomeServices />
               <HomeContact />
          </div>
     );
}