// media 
import vocacion from '../../assets/vocacion-icon.png';
import profesionalismo from '../../assets/profesionalismo-icon.png';
import compromiso from '../../assets/compromiso-icon.png'; 

export default function AboutPageValues() {
     return(
          <div className='w-screen bg-red-50 py-20 px-10 lg:px-20'>
               <div>
                    {/* main text */}
                    <div className='flex flex-col items-center gap-y-10 mb-10'>
                         <h2 className='font-semibold text-3xl lg:text-5xl'>NUESTROS VALORES EMPRESARIALES</h2>
                         <p className='text-xl text-gray-600 font-light text-justify'>
                              En Foverbagh nos esforzamos por tener un alto rendimiento en nuestras operaciones diarias, buscando siempre satisfacer de esta manera las necesidades de todos nuestros clientes, mantenemos siempre un alto perfil ético en todas las ejecuciones de nuestros procesos fomentando una gran actitud de trabajo en equipo, integridad corporativa, respeto entre las instituciones y la entrega de resultados de alta calidad, es por esto por lo que nuestras ideologías se encuentran alineadas con los siguientes valores empresariales:
                         </p>
                    </div>
                    {/* company values */}
                    <div className='flex flex-col lg:flex-row justify-center gap-x-10'>
                         <div className='p-5 lg:p-10 flex flex-col gap-y-5 lg:w-[30%]'>
                              <img src={vocacion} alt="" className='w-36' />
                              <h3 className='text-3xl font-semibold'>Vocación de servicio</h3>
                              <p className='font-light text-xl text-gray-600'>
                                   Nos esforzamos por satisfacer al máximo las necesidades y expectativas de nuestros clientes.
                              </p>
                         </div>
                         <div className='p-5 lg:p-10 flex flex-col gap-y-5 lg:w-[30%]'>
                              <img src={profesionalismo} alt="" className='w-36' />
                              <h3 className='text-3xl font-semibold'>Profesionalismo</h3>
                              <p className='font-light text-xl text-gray-600'>
                                   Nos conducimos con honestidad, ética y calidad en todas y cada una de nuestras acciones.
                              </p>
                         </div>
                         <div className='p-5 lg:p-10 flex flex-col gap-y-5 lg:w-[30%]'>
                              <img src={compromiso} alt="" className='w-36' />
                              <h3 className='text-3xl font-semibold'>Compromiso</h3>
                              <p className='font-light text-xl text-gray-600'>
                                   Cumplir siempre con los acuerdos que pactamos entre nosotros y nuestros clientes.
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     );
}