// components
import img from '../../assets/contacto-foverbagh.webp';

export default function ContactPageMain() {
     return(
          <div className='relative w-screen h-screen'>
               {/* text */}
               <div className='w-full h-full absolute flex items-center justify-center'>
                    <div className='w-[80%] md:w-[60%]'>
                         <h2 className='text-md md:text-xl text-white text-center'>CON GUSTO ATENDEREMOS TUS DUDAS</h2>
                         <h1 className='font-semibold text-4xl sm:text-5xl md:text-7xl text-white text-center'>¡Contáctanos!</h1>
                    </div>
               </div>
               {/* image */}
               <div className='w-screen h-screen'>
                    <img className='w-full h-full object-cover' src={img} alt="" />
               </div>
          </div>
     );
}