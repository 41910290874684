// react router
import { Link } from 'react-router-dom';
// hooks 
import { useState } from 'react';
// icons 
import { ArrowRightIcon } from '@heroicons/react/24/outline';
// assets 
import img1 from '../../assets/HOME-Penal.webp';
import img2 from '../../assets/HOME-Civil.webp';
import img3 from '../../assets/HOME-Mercantil.webp';
import img4 from '../../assets/HOME-Fiscal.webp';

export default function HomeServices() {

     const [hovered, setHovered] = useState(false);
     const [hovered2, setHovered2] = useState(false);
     const [hovered3, setHovered3] = useState(false);
     const [hovered4, setHovered4] = useState(false);

     return(
          <div className='w-screen bg-blue-50 pt-8'>
               <h3 className='text-4xl font-semibold text-center my-36'>Dentro de nuestras principales áreas legales de práctica se encuentran:</h3>
               {/* images */}
               <div className='w-full flex flex-col md:flex-row'>
                    <div className='w-full md:w-[25%] h-[500px] bg-black cursor-pointer relative' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                         <div className='z-[1] absolute bottom-10 left-10 flex flex-col gap-y-5 p-5'>
                         <h3 className='text-xl lg:text-2xl font-semibold text-white'>Penal</h3>
                         <div>
                              { 
                                   hovered && 
                                   <p className='text-white'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Curabitur aliquet quam id dui posuere blandit. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.
                                   </p>
                              }
                         </div>
                         </div>
                         <Link to='/services'>
                         <img className={`w-full h-full object-cover transition-opacity absolute z-[0] ${hovered ? 'opacity-60' : ''}`} src={img1} alt='' />
                         </Link>
                    </div>
                    <div className='w-full md:w-[25%] h-[500px] bg-black cursor-pointer relative' onMouseEnter={() => setHovered2(true)} onMouseLeave={() => setHovered2(false)}>
                         <div className='z-[1] absolute bottom-10 left-10 flex flex-col gap-y-5 p-5'>
                         <h3 className='text-xl lg:text-2xl font-semibold text-white'>Civil</h3>
                         <div>
                              { 
                                   hovered2 && 
                                   <p className='text-white'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Curabitur aliquet quam id dui posuere blandit. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.
                                   </p>
                              }
                         </div>
                         </div>   
                         <Link to='/services'>
                         <img className={`w-full h-full object-cover transition-opacity absolute z-[0] ${hovered2 ? 'opacity-60' : ''}`} src={img2} alt='' />
                         </Link>                
                    </div>
                    <div className='w-full md:w-[25%] h-[500px] bg-black cursor-pointer relative' onMouseEnter={() => setHovered3(true)} onMouseLeave={() => setHovered3(false)}>
                         <div className='z-[1] absolute bottom-10 left-10 flex flex-col gap-y-5 p-5'>
                         <h3 className='text-xl lg:text-2xl font-semibold text-white'>Mercantil</h3>
                         <div>
                              { 
                                   hovered3 && 
                                   <p className='text-white'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Curabitur aliquet quam id dui posuere blandit. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.
                                   </p>
                              }
                         </div>
                         </div>  
                         <Link to='/services'>
                         <img className={`w-full h-full object-cover transition-opacity absolute z-[0] ${hovered3 ? 'opacity-60' : ''}`} src={img3} alt='' />
                         </Link>                  
                    </div>
                    <div className='w-full md:w-[25%] h-[500px] bg-black cursor-pointer relative' onMouseEnter={() => setHovered4(true)} onMouseLeave={() => setHovered4(false)}>
                         <div className='z-[1] absolute bottom-10 left-10 flex flex-col gap-y-5 p-5'>
                         <h3 className='text-xl lg:text-2xl font-semibold text-white'>Fiscal</h3>
                         <div>
                              { 
                                   hovered4 && 
                                   <p className='text-white'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Curabitur aliquet quam id dui posuere blandit. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.
                                   </p>
                              }
                         </div>
                         </div>
                         <Link to='/services'>
                         <img className={`w-full h-full object-cover transition-opacity absolute z-[0] ${hovered4 ? 'opacity-60' : ''}`} src={img4} alt='' />
                         </Link>
                    </div>
               </div>
          </div>
     );
}