// media 
import logo from '../../assets/logo-white.png';
// react router
import { Link } from 'react-router-dom';
// icons
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// framer-motion
import { AnimatePresence, motion } from 'framer-motion';
// hooks
import { useState } from 'react';
// components 
import DropdownMenuModal from './DropdownMenuModal';

export default function Navbar() {

     const [ dropdownIsOpened, setDropdownIsOpened ] = useState(false);

     return(
          <div className='w-screen flex justify-between items-center py-10 px-10 xl:px-64 select-none absolute z-[1000]'>
               {/* logo section */}
               <div className='w-36 shrink-0'>
                    <Link to='/'>
                         <img src={logo} alt="Foverbagh Logo" />
                    </Link>
               </div>
               {/* nav section */}
               <div className='hidden md:flex gap-x-10 text-lg text-white font-semibold'>
                    <span className='hover:text-red-500'>
                         <Link to='/'>
                              Inicio
                         </Link>
                    </span>
                    <span className='hover:text-red-500'>
                         <Link to='/about'>
                              Nosotros
                         </Link>
                    </span>
                    <span className='hover:text-red-500'>
                         <Link to='/services'>
                              Servicios
                         </Link>
                    </span>
                    <span className='hover:text-red-500'>
                         <Link to='/blog'>
                              Blog
                         </Link>
                    </span>
                    <span className='hover:text-red-500'>
                         <Link to='/contact'>
                              Contacto
                         </Link>
                    </span>
               </div>
               {/* dropdown menu */}
               <div className='md:hidden'>
                    <Bars3Icon className='w-10 text-white hover:text-red-500 cursor-pointer' onClick={() => setDropdownIsOpened(true)} />
               </div>
               {/* dropdown menu */}
               <AnimatePresence>
                    {
                         dropdownIsOpened && (
                              <motion.div
                              className='fixed h-screen w-[50%] bg-white top-0 right-0 z-[10000] flex justify-center shadow-md'
                              initial={{ x: 500 }}
                              animate={{ x: 0 }}
                              exit={{ x: 500 }}
                              transition={{ duration: 0.5 }}
                              onMouseLeave={() => setDropdownIsOpened(false)}
                              >
                                   {/* x mark icon */}
                                   <XMarkIcon 
                                   className='absolute w-10 top-5 right-5 hover:text-red-500 cursor-pointer' 
                                   onClick={() => setDropdownIsOpened(false)}
                                   />
                                   {/* navs */}
                                   <div className='h-full w-full flex flex-col justify-center'>
                                        <DropdownMenuModal />
                                   </div>
                              </motion.div>
                         )
                    }
               </AnimatePresence>
          </div>
     );
}