// media 
import img from '../../assets/BLOG.webp';
// icons 
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function BlogPageMain() {
     return(
          <div className='w-screen h-screen relative'>
               {/* image */}
               <div className='w-full h-full'>
                    <img src={img} alt="" className='w-full h-full object-cover' />
               </div>
               {/* text */}
               <div className='absolute w-full h-full top-[45%] text-center px-10'>
                    <h1 className='text-5xl md:text-7xl font-semibold text-white mb-5'>Nuestro Blog</h1>
                    <div className='w-full flex justify-center'>
                         <ChevronDownIcon className='text-white w-10' />
                    </div>
               </div>
          </div>
     );
}